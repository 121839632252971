<template>
  <div
    v-if="!loadingData"
    class="content-wrapper"
  >

    <AddNovoColaborador
      :categoria-select="categoriaSelect"
      :show-modal="modalColaborador.showModal"
      :id-empresa="empresa.id_empresa"
      @confirmed="openModalSucesso"
      @canceled="closeModalColaborador"
    />

    <AddNovaVacinacao
      :show-modal="modalVacinacao.showModal"
      :lote-select="loteSelect"
      :vacinador-select="vacinadorSelect"
      :colaborador="modalVacinacao.colaborador"
      :empresa="empresa"
      :tipoCampanha="tipoCampanha"
      :id-campanha="idCampanha"
      :unidade="unidade"
      :idEndereco="endereco.id_empresa_endereco"
      @canceled="closeModalVacinacaoCancel"
      @confirmed="openModalSucesso"
    />

    <ConfirmModalVacinacao
      :modal-show="modalConfirm.showModal"
      :case-modal="modalConfirm.typeModal"
      :pessoa-remover="pessoaRemover"
      @remove="removeVacinacao"
      @confirmed="modalConfirm.confirmed"
      @canceled="modalConfirm.canceled"
    />

    <SucessoModal
      :modal-show="modalSucesso.showModal"
      :type-modal="modalSucesso.typeModal"
      :case-modal="modalSucesso.caseModal"
      @confirmed="closeModalSucesso"
    />

    <main class="card hidden_overflow_tabela">
      <div
        class="pt-2" 
      >
        <h2 class="h2-descricao-empresa-campanha">
          <span class="span-descricao-empresa">
          {{ empresa.nome_empresa }} -
          {{ empresa.documento_empresa | VMask(
            empresa.documento_empresa.length <= 11
              ? "###.###.###-##"
              : "##.###.###/####-##"
          ) }}
          </span>
          <span v-if="idCampanha" class="font_size_label span-descricao-campanha"
            >{{ moment().format("YYYY") }} - {{ descricaoCampanha }} |
            {{ unidade.descricao_unidade }} </span
          >
        </h2>
        <span v-if="endereco.id_empresa_endereco != null" class="span-descricao-endereco"
          >{{endereco.descricao}} - {{ formatEndereco(endereco) }}</span
        >
        <div class="pt-2 mx-auto col-11">
          <InfoSideCard :data="info.doses" />
        </div>
      </div>
      <div
        v-if="loadingData"
        class="d-flex justify-content-center my-3"
      >
        <div
          class="spinner-border text-custom-blue"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <hr>
      <div>
        <section
          v-if="!loadingData && !showFilter"
          class="d-flex align-items-center filters pb-1 px-2"
        >
          <div
            role="button"
            class="mr-auto"
            @click="
              () => {
                showFilter = true;
              }
            "
          >
            <feather-icon
              size="28"
              icon="FilterIcon"
              class="text-custom-blue"
            />
            <span class="ml-1 font-weight-bold text-custom-blue">FILTRAR</span>
          </div>
          <div>
            <b-button
              v-if="canInsertPerson"
              class="mr-2 cor_botao"
              variant="outline-primary"
              @click="openModalColaborador"
            >
              <feather-icon icon="UserPlusIcon" />
            </b-button>
            <b-button
              v-if="canExport"
              class="mr-2 cor_botao"
              variant="outline-primary"
              @click="downloadSpreadsheet"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </div>
        </section>
        <section
          v-if="!loadingData && showFilter"
          class="bg-blue filters py-1 px-2"
        >
          <b-row>
            <div
              class="px-1 mr-auto"
              role="button"
              @click="closeFilter"
            >
              <feather-icon
                size="28"
                icon="XIcon"
                class="text-custom-blue"
              />
              <span
                class="ml-1 font-weight-bold text-custom-blue"
              >REMOVER FILTRO</span>
            </div>
            <div>
              <b-button
                v-if="canInsertPerson"
                class="mr-2 cor_botao"
                variant="outline-primary"
                @click="openModalColaborador"
              >
                <feather-icon icon="UserPlusIcon" />
              </b-button>
              <b-button
                v-if="canExport"
                class="mr-2 cor_botao"
                variant="outline-primary"
                @click="downloadSpreadsheet"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </div>
          </b-row>
          <b-row class="pt-2">
            <b-col
              lg="10"
              class="d-flex p-0"
            >
              <b-col lg="3">
                <b-form-group
                  label="Status"
                  label-for="status-input"
                  label-class="font_size_label"
                >
                  <v-select
                    id="status-input"
                    v-model="search.status"
                    variant="custom-blue"
                    class="bg-white"
                    placeholder="Selecione um status"
                    label="label"
                    :options="statusSelect"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  label="Categoria"
                  label-for="categoria-input"
                  label-class="font_size_label"
                >
                  <v-select
                    id="categoria-input"
                    v-model="search.categoria"
                    variant="custom"
                    class="bg-white"
                    label="descricao"
                    :options="categoriaSelect"
                    placeholder="Selecione uma categoria"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  label="Nome"
                  label-for="nome-input"
                  label-class="font_size_label"
                >
                  <b-form-input
                    id="nome-input"
                    v-model="search.nome"
                    placeholder="Nome da pessoa"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  label="CPF"
                  label-for="cpf-input"
                  label-class="font_size_label"
                >
                  <b-form-input
                    id="cpf-input"
                    v-model="search.cpf"
                    v-mask="'###.###.###-##'"
                    placeholder="000.000.000-00"
                  />
                </b-form-group>
              </b-col>
            </b-col>
            <b-col lg="2">
              <b-button
                class="mt-2 col-12"
                variant="primary-button"
                @click="filter"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span class="align-middle">Filtrar</span>
              </b-button>
            </b-col>
          </b-row>
        </section>
        <section
          v-if="multiSelect.count && (canInsertMultiple || canExcludeMultiple)"
        >
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col
              lg="4"
              center
            >
              <b-button
                v-if="multiSelect.case === 'success' && canInsertMultiple"
                class="m-1 text-uppercase"
                variant="success"
                @click="vaccinateMultiple"
              >
                <span class="align-middle">VACINAR POR LOTE DE VACINAÇÃO</span>
              </b-button>
              <b-button
                v-if="multiSelect.case === 'danger' && canExcludeMultiple"
                class="m-1 text-uppercase"
                variant="danger"
                @click="unvacinatedMultiple"
              >
                <span class="align-middle">REMOVER VACINA</span>
              </b-button>
            </b-col>
            <b-col
              lg="8"
              class="align-items-center pb-1"
            >
              <span
                v-if="multiSelect.case === 'success' && canInsertMultiple"
                class="text-uppercase"
              >
                <feather-icon
                  size="28"
                  icon="XIcon"
                  class="text-custom-blue"
                  role="button"
                  @click="openModalConfirm('desmarcar')"
                />
                {{ multiSelect.count }} selecionados
                <a
                  class="text-custom-blue"
                  role="button"
                  @click="selectAllWithSameStatus(false)"
                >(Marcar todos os Não vacinados)</a>
              </span>
              <span
                v-if="multiSelect.case === 'danger' && canExcludeMultiple"
                class="text-uppercase"
              >
                <feather-icon
                  size="28"
                  icon="XIcon"
                  class="text-custom-blue"
                  role="button"
                  @click="openModalConfirm('desmarcar')"
                />
                {{ multiSelect.count }} selecionados
                <a
                  class="text-custom-blue"
                  role="button"
                  @click="selectAllWithSameStatus(true)"
                >(Marcar todos os vacinados)</a>
              </span>
            </b-col>
          </b-row>
        </section>
        <section
          v-if="!loadingData"
          class="list-vacinacao"
        >
          <b-table
            id="tableVacinacao"
            ref="tableVacinacao"
            responsive
            :sticky-column="true"
            :busy.sync="filtering"
            :fields="table.fields"
            :items="table.items"
            no-local-sorting
            :no-sort-reset="true"
            @sort-changed="ordenarColuna"
          >
            <template
              v-if="canInsertMultiple || canExcludeMultiple"
              #head(id_pessoa_fisica)
            >
              <b-form-checkbox
                v-if="!search.status || (search.status && search.status.value != 'Finalizado')"
                v-model="selectAll"
                :indeterminate="selectAll"
              />
            </template>
            <template
              v-if="canInsertMultiple || canExcludeMultiple"
              #cell(id_pessoa_fisica)="row"
            >
              <b-form-checkbox
                v-if="row.item.aplicacao.situacao !== 'Finalizada'"
                v-model="row.item.selected"
                @change="verifyIfTwoStatesSelected(row.item)"
              />
            </template>
            <template #cell(nome)="row">
              <span class="font-weight-bold comprimento_maximo_celulas">
                {{ row.item.nome }}
              </span>
            </template>
            <template #cell(cpf)="row">
              <span class="font-weight-bold">
                {{ row.item.cpf | VMask("###.###.###-##") }}
              </span>
            </template>
            <template #cell(categoria)="row">
              <span class="font-weight-bold">
                {{ row.item.categoria }}
              </span>
            </template>
            <template #cell(status)="row">
              <b-button
                v-if="foiVacinadoPorOutraEmpresa(row.item)"
                v-b-tooltip
                :title="formatDocumentCompany(row.item.aplicacao)"
                variant="link"
                class="id-empresa-aplicadora"
              >
                <b-form-checkbox
                  :checked="true"
                  :disabled="true"
                  class="custom-control-info"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <span
                      v-text="getSituacaoLabel(row.item.aplicacao.situacao)"
                    />
                  </span>
                </b-form-checkbox>
              </b-button>

              <b-form-checkbox
                v-else
                v-model="row.item.vacinado"
                :checked="false"
                class="custom-control-success"
                name="check-button"
                switch
                :disabled="deveDesabilitarBotao(row.item)"
                @change="changedState($event, row.item)"
              >
                <span class="switch-icon-left">
                  <span
                    v-text="getSituacaoLabel(row.item.aplicacao.situacao)"
                  />
                </span>
                <span class="switch-icon-right">
                  <span>Não vacinado</span>
                </span>
              </b-form-checkbox>
            </template>
          </b-table>
          <div class="pb-2 px-1">
            <span
              v-if="without"
              class="ml-1"
            >Nenhum colaborador encontrado</span>
            <b-col
              sm="12"
              class="mt-2"
            >
              <CustomPagination
                :paginacao="paginationData"
                @page-cliked="updateCurrentPage"
              />
            </b-col>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import { actions, subjects } from '@/libs/acl/rules'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import InfoSideCard from '@/views/components/custom/cards/InfoSideCard.vue'
import AddNovoColaborador from '@/views/components/custom/modals/vacinacao/Add-novo-colaborador.vue'
import AddNovaVacinacao from '@/views/components/custom/modals/vacinacao/Add-nova-vacinacao.vue'
import ConfirmModalVacinacao from '@/views/components/custom/modals/vacinacao/ConfirmModalVacinacao.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import { successMessage } from '@/libs/sweetalerts'

export default {
  title: 'Lista de registros de vacinação',

  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    vSelect,
    CustomPagination,
    BFormCheckbox,
    InfoSideCard,
    AddNovoColaborador,
    AddNovaVacinacao,
    ConfirmModalVacinacao,
    SucessoModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    }
  },

  data() {
    return {
      moment,
      linkItems: [
        {
          name: 'Vacinação',
          routeName: 'vacinacao-list',
        },
        {
          name: 'Registro de vacinação',
          active: true,
        },
      ],
      alert: {
        errorMessage: '',
        show: false,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      modalColaborador: {
        showModal: false,
      },
      modalVacinacao: {
        showModal: false,
        colaborador: null,
      },
      modalConfirm: {
        showModal: false,
        typeModal: 'remover',
        confirmed: this.closeModalConfirm,
        canceled: this.closeModalConfirm,
      },
      modalSucesso: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'criacao',
      },
      modalSucessoCancelamento: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'cancelamento',
      },
      timeID: null,
      filtering: false,
      loadingData: true,
      without: false,
      searchTerm: '',
      table: {
        fields: [
          {
            key: 'id_pessoa_fisica',
            label: '',
            sortable: false,
            class: 'mxw-15 pr-0',
          },
          {
            key: 'nome', label: 'NOME', sortable: true, class: 'mw-200',
          },
          {
            key: 'cpf', label: 'CPF', sortable: false, class: 'mw-150',
          },
          {
            key: 'categoria',
            label: 'CATEGORIA',
            sortable: false,
            class: 'mw-100',
          },
          {
            key: 'status', label: 'STATUS', sortable: false, class: 'mw-50',
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'nome',
        order: 'asc',
      },
      info: {
        doses: [
          {
            icon: 'ClipboardIcon',
            color: 'light-primary',
            title: 0,
            subtitle: 'Doses Contratadas',
          },
          {
            icon: 'CheckIcon',
            color: 'light-success',
            title: 0,
            subtitle: 'Doses Aplicadas',
          },
          {
            icon: 'LoaderIcon',
            color: 'light-warning',
            title: 0,
            subtitle: 'Doses Pendentes',
          },
        ],
      },
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      search: {
        status: '',
        categoria: '',
        nome: '',
        cpf: '',
      },
      statusSelect: [
        {
          label: 'Finalizado',
          value: 'Finalizado',
        },
        {
          label: 'Vacinado',
          value: 'Vacinado',
        },
        {
          label: 'Não vacinado',
          value: false,
        },
      ],
      canExclude: false,
      canExcludeMultiple: false,
      canInsert: false,
      canInsertMultiple: false,
      canExport: false,
      canInsertPerson: false,
      multiSelect: {
        count: 0,
        case: 'danger',
      },
      unidadeSelect: [],
      fornecedorSelect: [],
      categoriaSelect: [],
      loteSelect: [],
      vacinadorSelect: [],
      adesaoVacina: [],
      showFilter: false,
      selectAll: false,
      selectedCount: 0,
      unselectItemColab: null,
      pessoaRemover: 0,
      campanha: null,
    }
  },

  computed: {
    getSearch() {
      return this.params
    },

    idCampanha() {
      return this.getSearch.campanha.id_campanha
    },

    empresa() {
      return this.getSearch.empresa
    },

    unidade() {
      return this.getSearch.unidade
    },

    descricaoCampanha() {
      return this.getSearch.campanha.descricao_campanha
    },

    endereco() {
      return this.getSearch.endereco
    },

    tipoCampanha() {
      return this.getSearch.campanha.tipo
    },
  },

  watch: {
    selectAll(val) {
      this.verifyIfTwoStatesSelected()
      if (!val) {
        this.unselectAll()
        return
      }
      this.openModalConfirm('marcar')
      this.modalConfirm.confirmed = this.selectAllVaccinated
      this.modalConfirm.canceled = this.selectAllNonVaccinated
    },
  },

  async mounted() {
    await this.loadCampanha()

    this.canExclude = this.verifyPermissions(actions.EXCLUIR)
    this.canExcludeMultiple = this.verifyPermissions(actions.EXCLUIR_MULTIPLO)
    this.canInsert = this.verifyPermissions(actions.INSERIR)
    this.canInsertPerson = this.$can(
      actions.INSERIR,
      subjects.EMPRESA_COLABORADOR,
    )
    this.canInsertMultiple = this.verifyPermissions(actions.INSERIR_MULTIPLO)
    this.canExport = this.verifyPermissions(actions.EXPORTAR)

    this.removeFieldId()
  },

  methods: {
    loadData() {
      if (this.empresa) {
        this.without = false
        this.multiSelect.count = 0
        this.$http
          .get(this.$api.colaborador(), {
            params: {
              paginacao: true,
              tamanho: this.paginationData.defaultSize,
              pagina: this.paginationData.currentPage,
              colunaOrdenar: this.table.columnOrder,
              vacinado: this.search.status ? this.search.status.value : '',
              ordem: this.table.order,
              ordem_vacinados: 'desc',
              id_empresa: this.empresa.id_empresa,
              id_campanha: this.idCampanha,
              id_categoria: this.search.categoria
                ? this.search.categoria.id_categoria
                : '',
              cpf: this.search.cpf,
              ano: moment().format('YYYY'),
              ativo: true,
              nome: this.search.nome,
            },
          })
          .then(({ data }) => {
            this.table.items = data.data.map(item => ({
              ...item,
              ...(this.foiVacinadoPorOutraEmpresa(item)
                ? {
                  _rowVariant: 'custom-secondary',
                }
                : {}),
            }))
            if (data.length == 0) {
              this.without = true
            }

            this.table.items = data.data

            this.filtering = false
            this.selectAll = false
            this.startPagination(data)
            this.loadingData = false
          })
      } else {
        setTimeout(() => {
          this.loadData()
        }, 200)
      }
    },
    loadCampanha() {
      this.loadDoses()
      this.loadData()
      this.loadInfoUnity()
    },
    async loadInfoUnity() {
        this.loadLotes()

        const categories = await this.$http.get(this.$api.getEmpresaCategoria())
        this.categoriaSelect = categories.data

        const params = {
          unidadesId: [this.unidade.id_unidade],
        }

        const vacinador = await this.$http.get(this.$api.vacinadores(), { params })
        this.vacinadorSelect = vacinador.data
    },
    async loadDoses() {
      if (this.idCampanha && this.empresa.id_empresa) {
        let adesoes = {};

        if(this.endereco.id_empresa_endereco){
          adesoes = await this.$http.get(
            this.$api.adesaoCampanhaEmpresaEnderecoParameter(
              this.idCampanha,
              this.empresa.id_empresa,
              this.endereco.id_empresa_endereco
            )
          );
        }else{
          adesoes = await this.$http.get(
            this.$api.adesaoCampanhaEmpresaParameter(
              this.idCampanha,
              this.empresa.id_empresa,
            ),
          )
        }

        this.info.doses[0].title = adesoes.data.quantidade_total ?? 0
        this.info.doses[1].title = adesoes.data.quantidade_aplicada ?? 0
        this.info.doses[2].title = adesoes.data.quantidade_nao_aplicada ?? 0
      } else {
        setTimeout(() => {
          this.loadDoses()
        }, 200)
      }
    },
    async loadLotes() {
      const parameters = {
        id_unidade: this.unidade.id_unidade,
        id_campanha: this.idCampanha,
        id_empresa: this.empresa.id_empresa,
        ativo: false,
      }
      const lotes = await this.$http.get(this.$api.lote(), {
        params: parameters,
      })
      const lotesFiltered = lotes.data.filter(lote => lote.saldo > 0)

      this.loteSelect = lotesFiltered.sort((a, b) => a.numero_lote.localeCompare(b.numero_lote))
    },
    startPagination(data) {
      this.paginationData.currentPage = data.meta.current_page
      this.paginationData.totalLines = data.meta.total
      this.paginationData.fromLine = data.meta.from
      this.paginationData.toLine = data.meta.to
    },
    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.filtering = true
      this.loadData()
    },
    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy
      this.table.order = sort.sortDesc ? 'desc' : 'asc'

      this.filtering = true

      this.loadData()
    },
    changedState(state, colaborador) {
      if (state) {
        this.modalVacinacao.colaborador = [colaborador]
        this.openModalVacinacao()
      } else {
        this.modalVacinacao.colaborador = [colaborador]
        this.openModalConfirm('remover')
      }
    },
    vaccinateMultiple() {
      const selected = this.table.items.filter(item => item.selected)
      this.modalVacinacao.colaborador = selected
      this.openModalVacinacao()
    },
    unvacinatedMultiple() {
      const selected = this.table.items.filter(item => item.selected && item.aplicacao.situacao != 'Finalizada')
      this.modalVacinacao.colaborador = selected
      this.pessoaRemover = selected.length || 1

      this.openModalConfirm('removerMultiplo')
    },
    filter() {
      if (this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
        this.without = false

        clearTimeout(this.timeID)
        this.timeID = setTimeout(() => {
          this.paginationData.currentPage = 1
          this.filtering = true
          this.loadData()
        }, 500)
      }
    },
    verifyIfTwoStatesSelected(i = null) {
      const selected = this.table.items.filter(
        item => item.selected
              && item.vacinado
              && item.aplicacao.situacao != 'Finalizada',
      )
      const notSelected = this.table.items.filter(
        item => item.selected && !item.vacinado,
      )

      if (selected.length && notSelected.length) {
        this.openModalConfirm('selecionar')

        this.modalConfirm.confirmed = this.unselectItem
        this.unselectItemColab = i
        return
      }

      if (selected.length > 1) {
        this.multiSelect.count = selected.length
        this.multiSelect.case = 'danger'
      }

      if (notSelected.length > 1) {
        this.multiSelect.count = notSelected.length
        this.multiSelect.case = 'success'
      }

      if (selected.length <= 1 && notSelected.length <= 1) {
        this.multiSelect.count = 0
      }
    },
    unselectItem() {
      this.table.items.map(item => {
        if (item.id_pessoa_fisica == this.unselectItemColab.id_pessoa_fisica) {
          item.selected = false
        }
      })
      this.$refs.tableVacinacao.refresh()
      this.unselectItemColab = null
      this.closeModalConfirm()
    },
    openModalColaborador() {
      this.modalColaborador.showModal = true
    },
    closeModalColaborador() {
      this.modalColaborador.showModal = false
    },
    openModalVacinacao() {
      this.modalVacinacao.showModal = true
    },
    closeModalVacinacao() {
      this.modalVacinacao.colaborador = ''
      this.modalVacinacao.showModal = false
    },
    closeModalVacinacaoCancel() {
      const colab = this.modalVacinacao.colaborador ?? [];

      if(colab.length == 1) {
        let selectedColab = this.table.items.find(
          item => item.id_pessoa_fisica == colab[0].id_pessoa_fisica
        )
        selectedColab.vacinado = !selectedColab.vacinado
      }
      
      this.modalVacinacao.colaborador = ''
      this.modalVacinacao.showModal = false
    },
    openModalSucesso() {
      this.closeModalColaborador()
      this.modalVacinacao.showModal = false
      this.modalSucesso.showModal = true
    },
    closeModalSucesso() {
      this.modalSucesso.showModal = false
      this.loadingData = true
      this.loadLotes()
      this.loadDoses()
      this.loadData()
    },

    openModalConfirm(type) {
      this.modalConfirm.showModal = true
      this.modalConfirm.typeModal = type
      if (type == 'desmarcar') {
        this.modalConfirm.confirmed = this.unselectAll
        this.modalConfirm.canceled = this.closeModalConfirm
      }
    },
    unselectAll() {
      this.multiSelect.count = 0
      this.selectAll = false
      this.table.items.map(item => {
        item.selected = false
      })
      this.closeModalConfirm()
    },
    selectAllWithSameStatus(status) {
      const toSelect = this.table.items.filter(item => item.vacinado == status)

      toSelect.map(item => {
        item.selected = true
      })
      this.$refs.tableVacinacao.refresh()
    },
    unselectAllWithSameStatus(status) {
      const toSelect = this.table.items.filter(item => item.vacinado == status)

      toSelect.map(item => {
        item.selected = false
      })
      this.$refs.tableVacinacao.refresh()
    },
    selectAllVaccinated() {
      this.unselectAllWithSameStatus(false)
      const toSelect = this.table.items.filter(item => item.vacinado)

      toSelect.map(item => {
        item.selected = true
      })
      this.$refs.tableVacinacao.refresh()
      this.verifyIfTwoStatesSelected()
      this.closeModalConfirm()
    },
    selectAllNonVaccinated() {
      this.unselectAllWithSameStatus(true)
      const toSelect = this.table.items.filter(item => !item.vacinado)

      toSelect.map(item => {
        item.selected = true
      })
      this.$refs.tableVacinacao.refresh()
      this.verifyIfTwoStatesSelected()
      this.closeModalConfirm()
    },
    downloadSpreadsheet() {
      this.$http
        .get(this.$api.colaborador(), {
          params: {
            paginacao: false,
            vacinado: this.search.status ? this.search.status.value : '',
            ordem: this.table.order,
            id_empresa: this.empresa.id_empresa,
            id_campanha: this.idCampanha,
            id_categoria: this.search.categoria
              ? this.search.categoria.id_categoria
              : '',
            cpf: this.search.cpf,
            ano: moment().format('YYYY'),
            ativo: true,
          },
        })
        .then(({ data }) => {
          const content = data.data.map(item => ({
            nome: item.nome,
            cpf: item.cpf,
            'data de nascimento': moment(item.data_nascimento).format('DD/MM/YYYY'),
            categoria: item.categoria,
            vacinado: item.vacinado ? 'Vacinado' : 'Não vacinado',
          }))

          this.$helpers.downloadArchiveXlsx(content, 'planilha_vacinacao.xlsx')
        })
    },
    closeModalConfirm() {
      this.closeModalVacinacaoCancel()
      this.modalConfirm.showModal = false
      this.pessoaRemover = 0
    },
    openModalError() {
      this.modalError.showModal = true
    },
    closeFilter() {
      this.showFilter = false
      this.search.nome = ''
      this.search.status = ''
      this.search.categoria = ''
      this.search.cpf = ''
      this.filtering = true
      this.paginationData.currentPage = 1
      this.loadData()
    },
    removeVacinacao() {
      const { colaborador } = this.modalVacinacao
      this.loadingData = true
      this.unselectAll()
      this.pessoaRemover = colaborador.length || 1

      const pessoas = colaborador.map(element => ({
        id_aplicacao: element.aplicacao.id_aplicacao,
        motivo_cancelamento: 'Cancelamento realizado via sistema',
      }))

      this.$http
        .post(this.$api.vacinacaoCancelar(), { pessoas })
        .then(response => {
          if (response.status === 201) {
            successMessage('Cancelamento realizado com sucesso!')

            this.table.items = []

            this.paginationData = {
              currentPage: 1,
              totalLines: 0,
              fromLine: 0,
              toLine: 0,
              defaultSize: 10,
            }

            this.closeModalVacinacao()

            this.modalSucessoCancelamento.showModal = false
            this.loadLotes()
            this.loadDoses()
            this.loadData()
          }
        })
        .catch(err => {
          this.modalError.caseModal = 'naoInativo'
          this.openModalError()
          this.loadData()
        })
    },
    verifyPermissions(action) {
      const permission = this.$can(
        actions[action],
        subjects.REGISTRO_VACINACAO_SESI,
      )

      return [permission].includes(true)
    },
    removeFieldId() {
      if (!this.canExcludeMultiple && !this.canInsertMultiple) {
        this.table.fields.shift()
      }
    },
    getSituacaoLabel(situacao) {
      return situacao == 'Finalizada' ? situacao : 'Vacinado'
    },

    foiVacinadoPorOutraEmpresa(item) {
      return item.aplicacao.outra_empresa_aplicadora
    },

    deveDesabilitarBotao(item) {
      return (
        (!item.vacinado && !this.canInsert)
          || (item.vacinado && !this.canExclude)
          || item.aplicacao.situacao == 'Finalizada'
      )
    },

    formatDocumentCompany(item) {
      const titleDocument = `Vacinado na empresa ${item.nome_fantasia_empresa_aplicadora} de `

      if (item.cpf_empresa_aplicadora) {
        return `${titleDocument}CPF ${this.$options.filters.VMask(item.cpf_empresa_aplicadora, '###.###.###-##')}`
      }
      return `${titleDocument}CNPJ ${this.$options.filters.VMask(item.cnpj_empresa_aplicadora, '##.###.###/####-##')}`
    },

    formatEndereco(endereco) {
        let descricao = '';

        if(endereco.logradouro) {descricao += `${endereco.logradouro}, ` };
        if(endereco.numero) { descricao += `${endereco.numero}, ` };
        if(endereco.bairro) { descricao += `${endereco.bairro}, ` };
        if(endereco.nome_cidade) { descricao += `${endereco.nome_cidade}, ` };
        if(endereco.uf) { descricao += `${endereco.uf}` };

        return descricao;
    },
  },
}
</script>

<style scoped>
.font_size_label {
  font-size: 15px;
  margin-top: -5px;
}

.custom {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}

.cor_botao {
  color: #2772c0 !important;
  border: 1px solid #2772c0 !important;
}

.altura_max_scrollbar {
  max-height: 100px;
  padding-right: 15px;
}

.comprimento_maximo_celulas {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.hidden_overflow_tabela {
  overflow: hidden;
}

.custom-switch >>> .custom-control-label .switch-icon-left {
  left: 20px;
  transform: translateX(8px);
}

.custom-switch >>> .custom-control-label::before {
  width: 130px;
}

.custom-switch
>>> .custom-control-input:checked
~ .custom-control-label::after {
  transform: translateX(7.5rem);
}

.custom-switch >>> .custom-control-label .switch-icon-right {
  transform: translateX(90px);
  width: 100px;
}

#tableVacinacao >>> thead th {
  border-bottom: 2px solid #2772c0 !important;
  background-color: #2772c0 !important;
  color: white;
}

.bg-blue {
  background-color: #f2f7fc;
}

.id-empresa-aplicadora {
  width: 100%;
  text-align: left;
  display: inline-block;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  border-color: none !important;
  background-color: none !important;
}

.span-descricao-campanha {
  background: #F5F6F9;
  border-radius: 20px;
  padding: 5px 10px;
  text-align: right;
  margin-top: 0;
}

.span-descricao-empresa {
  flex: 1;
}

.h2-descricao-empresa-campanha {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

</style>
